



























































































import router from '@/router';
import { mapState, mapGetters } from 'vuex';

import { DataTableHeader } from 'vuetify';
import { AxiosError } from 'axios';
import {
  EMissionRewardType,
  EMissionStatus,
  IMissionItem,
  IMissionListParams,
  SelectItem
} from '@/api/schema';

import { getMissionList, updateMissionStatus } from '@/api/Missions';
import { statusOptions, enabledStatusConfig } from '@/helpers/missionHelpers';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { toPercents } from '@/helpers/decimalsHelpers';

import { REWARD_TYPE_OPTIONS } from '@/helpers/missionHelpers';

import MissionConfirmationDialog from '@/views/MissionsView/MissionConfirmationDialog.vue';

const defaultOptions = {
  page: 1,
  itemsPerPage: 10
};

export default {
  name: 'MissionsView',
  components: { MissionConfirmationDialog },

  data(): any {
    return {
      EMissionStatus,
      EMissionRewardType,
      REWARD_TYPE_OPTIONS,

      filterStatus: null,
      options: { ...defaultOptions },
      missions: [],
      totalElements: null,
      loading: false
    };
  },
  computed: {
    ...mapState('app', ['isSuperAdmin']),
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapGetters('FeatureFlags', [
      'featuresLoading',
      'isEnabledFeatureFlag'
    ]),

    isEnabledMissions(): boolean {
      return this.isEnabledFeatureFlag('mission_management');
    },

    statusOptions: (): SelectItem[] => statusOptions,

    tableHeaders(): DataTableHeader[] {
      return [
        {
          text: 'ID',
          value: 'id',
          sortable: false
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false
        },
        {
          text: 'Start Date',
          value: 'startDate'
        },
        {
          text: 'Due Date',
          value: 'dueDate'
        },
        {
          text: 'Active / Total Users',
          value: 'totalUsers',
          sortable: false
        },
        {
          text: 'Reward Type',
          value: 'rewardType',
          sortable: false
        },
        {
          text: 'Total Reward',
          value: 'rewardAmount',
          sortable: false
        },
        {
          text: 'Status',
          value: 'status',
          width: 176,
          sortable: false
        },
        {
          text: 'Action',
          value: 'action',
          width: 100,
          sortable: false
        }
      ];
    },

    requestParams(): IMissionListParams {
      const {
        page,
        itemsPerPage,
        sortBy: [sort],
        sortDesc: [desc]
      } = this.options;

      return {
        status: this.filterStatus,
        page: page - 1,
        size: itemsPerPage,
        ...(sort && { direction: desc ? 'DESC' : 'ASC' }),
        sort
      };
    },

    watchGroup(): any {
      return [this.operatorId, this.options];
    }
  },

  watch: {
    featuresLoading: {
      handler(value: boolean): void {
        if (value || this.isEnabledMissions || this.isSuperAdmin) return;

        router.push('/');
      },
      immediate: true
    },
    watchGroup: 'getMissions',
    filterStatus: {
      handler(): void {
        this.options.page = 1;
        this.getMissions();
      }
    }
  },

  methods: {
    getMissions(): void {
      if (!this.operatorId) return;

      this.loading = true;

      getMissionList(this.requestParams)
        .then((response) => {
          this.missions = response.content;
          this.totalElements = response.totalElements;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    formatCountUsers(mission: IMissionItem): string {
      const { activeUsers, totalUsers } = mission;

      const percent = toPercents(activeUsers / totalUsers, 1);

      return `${activeUsers} / ${totalUsers} (${percent}%)`;
    },

    checkDisabledStatus(
      current: EMissionStatus,
      selected: EMissionStatus
    ): boolean {
      return !enabledStatusConfig[current].includes(selected);
    },

    confirmationDialog(
      status: EMissionStatus,
      missionName: string
    ): Promise<boolean> {
      return this.$refs.confirmationDialog.openDialog(status, missionName);
    },

    async handlerChangeStatus(
      status: EMissionStatus,
      mission: IMissionItem,
      cb?: () => void
    ): Promise<void> {
      if (![EMissionStatus.DRAFT].includes(status)) {
        const confirm = await this.confirmationDialog(status, mission.name);

        if (!confirm) return;
      }

      updateMissionStatus(mission.id, status)
        .then(() => {
          const statusName = status.toLowerCase();

          mission.status = status;
          cb();

          this.$toast.success(
            `Mission “${mission.name}” successfully changed its status to ${statusName}`
          );
        })
        .catch((error: AxiosError) => {
          errorToastMessage(error);
          throw error;
        });
    },

    async handleDeleteMission(mission: IMissionItem): Promise<void> {
      this.$refs.selectStatus.blur();

      const confirm = await this.confirmationDialog(
        EMissionStatus.DELETED,
        mission.name
      );

      if (!confirm) return;

      updateMissionStatus(mission.id, EMissionStatus.DELETED)
        .then(() => {
          this.$toast.success(
            `Mission “${mission.name}” was successfully deleted`
          );
          this.getMissions();
        })
        .catch((error: AxiosError) => {
          errorToastMessage(error);
          throw error;
        });
    }
  }
};
