var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mission-view",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex align-center justify-space-between mb-6"},[_c('h2',{staticClass:"text-h5 font-weight-medium"},[_vm._v("Missions")]),_c('div',{staticClass:"d-flex gap-4 w-100"},[_c('v-select',{staticClass:"mission-view__filter",attrs:{"items":_vm.statusOptions,"menu-props":{ offsetY: true },"hide-details":"","label":"Status","outlined":"","clearable":"","dense":""},model:{value:(_vm.filterStatus),callback:function ($$v) {_vm.filterStatus=$$v},expression:"filterStatus"}}),_c('v-btn',{staticClass:"primary",attrs:{"color":"success","type":"button","to":{ name: 'account-operator-mission-create' }}},[_c('span',{staticClass:"mr-2"},[_vm._v("Create Mission")]),_c('v-icon',[_vm._v("mdi-arrow-right")])],1)],1)]),_c('v-data-table-with-pagination',{staticClass:"mission-view__table",attrs:{"headers":_vm.tableHeaders,"items":_vm.missions,"options":_vm.options,"loading":_vm.loading,"server-items-length":_vm.totalElements},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-select',{ref:"selectStatus",attrs:{"items":_vm.statusOptions,"value":item.status,"status":"","solo":"","dense":"","hide-details":"","menu-props":{ offsetY: true },"disabled":_vm.loading},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var attrs = ref.attrs;
var click = ref.on.click;
var ref_item = ref.item;
var text = ref_item.text;
var value = ref_item.value;
return [_c('v-list-item',_vm._b({attrs:{"active-class":"text--disabled","disabled":_vm.checkDisabledStatus(value, item.status)},on:{"click":function($event){return _vm.handlerChangeStatus(value, item, click)}}},'v-list-item',Object.assign({}, attrs),false),[_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(text))])])]}}],null,true)})]}},{key:"item.rewardType",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.REWARD_TYPE_OPTIONS[item.rewardType] || '-'))])]}},{key:"item.totalUsers",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCountUsers(item)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex gap-2"},[(item.status !== _vm.EMissionStatus.DRAFT)?_c('v-btn',{attrs:{"icon":"","text":"","small":"","disabled":_vm.loading,"to":{ name: 'account-operator-mission-view', params: { missionId: item.id } }}},[_c('v-icon',{attrs:{"title":"View"}},[_vm._v("mdi-eye")])],1):_c('v-btn',{attrs:{"icon":"","text":"","small":"","disabled":_vm.loading,"to":{ name: 'account-operator-mission-edit', params: { missionId: item.id } }}},[_c('v-icon',{attrs:{"title":"Edit event"}},[_vm._v("mdi-pencil-outline")])],1),_c('v-btn',{attrs:{"icon":"","text":"","small":"","disabled":item.status === _vm.EMissionStatus.ACTIVE || _vm.loading},on:{"click":function($event){return _vm.handleDeleteMission(item)}}},[_c('v-icon',{attrs:{"title":"Delete"}},[_vm._v("mdi-delete-outline")])],1)],1)]}}])}),_c('MissionConfirmationDialog',{ref:"confirmationDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }