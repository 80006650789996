




























import BaseDialog from '@/components/BaseDialog.vue';
import { confirmationConfig } from '@/helpers/missionHelpers';

const defaultConfirmData = {
  title: null,
  content: null,
  warn: null,
  button: {
    text: null,
    color: null
  }
};
export default {
  name: 'MissionConfirmationDialog',

  components: {
    BaseDialog
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data(): any {
    return {
      open: false,
      status: null,
      missionName: null,
      resolve: null
    };
  },

  computed: {
    confirmInfo(): any {
      return confirmationConfig[this.status] || defaultConfirmData;
    }
  },

  methods: {
    openDialog(status: string, missionName: string): Promise<boolean> {
      this.status = status;
      this.missionName = missionName;
      this.open = true;

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },

    confirm(): void {
      this.resolve(true);
      this.open = false;
    },

    cancel(): void {
      this.resolve(false);
      this.open = false;
    }
  }
};
